import { useEffect, useState } from "react";
import "./assets/styles/HomePage.css";
import StartAnimation from "./components/StarAnimation";
import Swal from "sweetalert2";
import Congrat from "./components/congrat/Congrat";
import { getCode } from "./controllers/api.controller";
import sunrise from "./assets/images/sunrise.png";
import bottom from "./assets/images/bottom.png";
import { information } from "./const/info.const";
import captchaGenerator from "./components/captchaGenerator";
import { Box, Button, Modal, Typography } from "@mui/material";

const HomePage = () => {
  const host = window.location.hostname;
  const [site, setSite] = useState("");
  useEffect(() => {
    if (information.f8bet.includes(window.location.hostname) || information.regex_url_f8bet.test(window.location.hostname)) {
      document.getElementsByClassName("logo")[0].setAttribute("src", "/images/logo_f8bet.png");
      document.getElementsByClassName("text-img")[0].setAttribute("src", "/images/text_f8bet.png");
      document.getElementById("home-page").classList.add("bg-f8bet");
      document.getElementsByClassName("input-cont")[0].classList.add("input-cont-f8bet");
      document.getElementById("submit-btn").classList.add("submit-btn-f8bet");
      localStorage.setItem("site-redeem", "f8bet");
      setSite("f8bet");
      document.getElementsByClassName("character-pic")[0].style.display = "none";
    } else if (information.hi88.includes(window.location.hostname)) {
      document.getElementsByClassName("logo")[0].setAttribute("src", "/images/logo_hi88.png");
      document.getElementsByClassName("text-img")[0].setAttribute("src", "/images/text_hi88.png");
      document.getElementById("home-page").classList.add("bg-hi88");
      document.getElementsByClassName("input-cont")[0].classList.add("input-cont-hi88");
      document.getElementById("submit-btn").classList.add("submit-btn-hi88");
      localStorage.setItem("site-redeem", "hi88");
      setSite("hi88");
    } else if (information.jun88.includes(window.location.hostname)) {
      document.getElementsByClassName("logo")[0].setAttribute("src", "/images/logo_jun88.png");
      document.getElementsByClassName("text-img")[0].setAttribute("src", "/images/text_jun88.png");
      document.getElementById("home-page").classList.add("bg-jun88");
      document.getElementsByClassName("input-cont")[0].classList.add("input-cont-jun88");
      document.getElementById("submit-btn").classList.add("submit-btn-f8bet");
      localStorage.setItem("site-redeem", "jun88");
      setSite("jun88");

      document.getElementsByClassName("character-pic")[0].style.display = "none";
    }
  }, [site]);
  useEffect(() => {
    document.getElementById("submit-btn").addEventListener("click", () => {
      let codeID = document.getElementById("code-id").value.replace(/\s/g, "");

      // if (codeID.startsWith("0") && codeID.length === 10) {
      //   // Loại bỏ số 0 ở đầu chuỗi
      //   codeID = codeID.replace(/^0+/, "");
      // }
      if (codeID === "") {
        Swal.fire({
          icon: "error",
          title: "Thất bại!",
          text: "Vui lòng nhập đầy đủ thông tin!",
        });
      } else {
        const codeID_reg = codeID.replace(/^(0|\+84 |84 |\+84)/, "84");
        if (document.getElementById("submit-btn").getAttribute("click-id") === "false") {
          document.getElementById("submit-btn").setAttribute("click-id", "true");
          Swal.fire({
            title: "Xác thực",
            showConfirmButton: false,
            html: `
            <div class="input-form-captcha-wrapper">
            <div id="captcha"></div>
            <div class="input-form-captcha">
              <input type="text" placeholder="Nhập mã captcha" id="captcha-input"></input>
            </div>
          </div>
            `,
          });
          captchaGenerator("captcha", "captcha-input", codeID_reg);
        }
      }
    });
  }, []);
  return (
    <div id="home-page">
      <div className="warpper">
        <img className="logo" alt="" />
        <img className="text-img" alt="" />
        <div className="box-img">
          <div className="input-cont">
            <label className="box-label">{(information.phone_page[site]?.includes(host) || information.regex_url_f8bet.test(window.location.hostname)) ? "NHẬP SĐT VÀ CODE ĐỂ NHẬN THƯỞNG" : "NHẬP CODE ĐỂ NHẬN THƯỞNG"}</label>
            <div className="input-item-cont">
              <input id="code-id" type="text" placeholder="Số điện thoại và code" autoComplete="off"></input>
            </div>
            {site && site === "jun88" && host != "freecode.jun88.fan" && (
              <div className="div-support">
                {information.phone_page[site].includes(host) && (
                  <>
                    <p className="support-content">
                      Hướng dẫn nhập code: Nhập Số điện thoại + mã CODE <br></br> (
                      <span variant="span" sx={{ fontStyle: "italic", color: "red" }}>
                        5 ký tự trong dấu ngoặc đơn trong tin nhắn được mời, <br></br>ví dụ mã CODE [ MwvEU ]
                      </span>
                      )
                    </p>
                    <span className="example">Ví dụ: 0949982186MwvEU</span>
                  </>
                )}
              </div>
            )}
            {site && site === "hi88" && (
              <div className="div-support">
                {information.phone_page[site].includes(host) && (
                  <>
                    <p className="support-content">Hướng dẫn nhập code: Số điện thoại bắt đầu bằng 84</p>
                    <span className="example">Ví dụ: 84949981192 </span>
                  </>
                )}
              </div>
            )}
            {site && site === "f8bet" && (
              <div className="div-support">
                {(information.phone_page[site].includes(host) || information.regex_url_f8bet.test(window.location.hostname)) && (
                  <>
                    <p className="support-content">Hướng dẫn nhập code: </p>
                    <p className="support-content">- CODE68K: Nhập mã CODE nhận được</p>
                    <p className="support-content">
                      - CODE100K: Nhập Số điện thoại + mã CODE <br></br> (
                      <span variant="span" sx={{ fontStyle: "italic", color: "red" }}>
                        5 ký tự trong dấu ngoặc đơn trong tin nhắn được mời, <br></br>ví dụ mã CODE [ nQmdM ]
                      </span>
                      )
                    </p>
                    <span className="example">Ví dụ: 0949981192nQmdM </span>
                  </>
                )}
              </div>
            )}
            <div id="submit-btn" click-id="false">
              KIỂM TRA NGAY
            </div>
          </div>
        </div>
      </div>
      <StartAnimation />
      <Congrat />
      <img src={sunrise} className="sunrise" alt="" />
      <img src={bottom} className="character-pic" alt="" />
    </div>
  );
};

export default HomePage;

/**
 * https://vip.hi88.love/
https://code.f8bet.org/
https://code.jun26.com >> JunCMD
 * */
